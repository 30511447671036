import sparkGenericDefaults from '../shopify-spark-generic/config.js';

const ignoreFields = sparkGenericDefaults.Widgets.find((w) => w.name === 'Facets')?.ignoreFields;

export default {
  includes: ['shopify-spark-generic'],
  ...sparkGenericDefaults,
  Widgets: [
    ...sparkGenericDefaults.Widgets.filter(
      (w) => !['Facets', 'SearchVehicleWidget', 'CategoryPage'].includes(w.name),
    ),
    {
      name: 'CategoryPage',
      type: 'SearchPage',
      location: 'body.template-collection .page-collections .page-content',
      template: 'MainContent',
    },
    {
      name: 'FacetPanelCategory',
      type: 'SearchPage',
      location: { lastChildOf: 'body.template-collection .page-collections .page-sidebar' },
      template: 'FacetPanelContainer',
    },
    {
      name: 'Facets',
      type: 'FacetPanel',
      ignoreFields,
      initCollapsed: true,
      initExpandedFacets: ['category', 'vendor', 'price', 'Part Type'],
    },
    {
      name: 'SearchVehicleWidget',
      type: 'VehicleWidget',
      fields:
        !!window.Convermax.config?.extraFieldsAsFilters && sparkGenericDefaults.fitmentSearch.baseFields,
      visibleIf: () => !window.location.pathname.includes('/collections'),
    },
  ],
};
